import clsx from 'clsx';
import Image from 'next/image';
import {useRouter} from 'next/router';
import ReactMarkdown, {Options} from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeRewrite from 'rehype-rewrite';

import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {withErrorBoundary} from 'components/error/ErrorBoundary';

// import doorImg from 'components/content-components/CarCategoryFlipbox/car_door.png';
// import {withErrorBoundary} from 'components/error/ErrorBoundary';
// import rehypeSanitize from 'rehype-sanitize';
// import StarcarTrenner from 'components/strapi-components/StarcarTrenner';
import {Button} from '../Button';
import {CustomIcon} from '../CustomIcon/CustomIcon';
import {NextLink} from '../NextLink/Link';
// import Icon from '../Icon';
// import Link from '../Link';
// import Title from '../Title';
import styles from './legacyContentStyles.module.scss';
import {IMardownContentProps} from './types';

const MarkdownHeading = ({...props}) => {
  let variant;
  let className;
  switch (props.level) {
    case 1:
      variant = 'h1';
      break;
    case 2:
      variant = 'h2';
      break;
    case 3:
      variant = 'h3';
      break;
    case 4:
      variant = 'h4';
      break;
    case 5:
      variant = 'h5';
      break;
    default:
      variant = 'h2';
      break;
  }
    switch (props.className) {
    case "h1":
      className = 'h1';
      break;
    case "h2":
      className = 'h2';
      break;
    case "h3":
      className = 'h3';
      break;
    case "h4":
      className = 'h4';
      break;
    case "h5":
      className = 'h5';
      break;
    default:
      break;
  }
  return (
    <Typography
      component={variant}
      className={clsx({
        [styles.h1]: (className || variant) === 'h1',
        [styles.h2]: (className || variant) === 'h2',
        [styles.h3]: (className || variant) === 'h3',
        [styles.h4]: (className || variant) === 'h4',
        [styles.h5]: (className || variant) === 'h5',
      })}
    >
      {props.children}
    </Typography>
  );
};
const MarkdownListItem = ({...props}): JSX.Element => {
  const isBold = props?.node?.isBold;
  const isCheckmark = props?.isCheckmark;
  return (
    <li
      hidden={props.tagName === 'ol'}
      style={props.style}
      className={`${isBold ? styles.boldMarker : ''} ${isCheckmark ? styles.checkmark : ''}`}
    >
      {isCheckmark ? (
        <Box sx={{height: '24px'}}>
          <CustomIcon
            name="checkmark"
            style={{
              height: '24px',
              width: '24px',
              marginRight: '5px',
              verticalAlign: 'middle',
            }}
          />
        </Box>
      ) : null}
      {props.children}
    </li>
  );
};
const MarkdownListItemOl = ({...props}): JSX.Element => {
  return <ol style={props.style}>{props.children}</ol>;
};

function MarkdownTable({children}): JSX.Element {
  return (
    <TableContainer component={Paper} sx={{backgroundColor: '#f3f3f3'}}>
      <Table size="small" aria-label="a dense table">
        {children}
      </Table>
    </TableContainer>
  );
}
function MarkdownTableCell({children}): JSX.Element {
  return (
    <TableCell sx={{color: '#000'}}>
      <Typography>{children}</Typography>
    </TableCell>
  );
}
function MarkdownTableRow({children}): JSX.Element {
  return <TableRow>{children}</TableRow>;
}
function MarkdownTableBody({children}): JSX.Element {
  return <TableBody>{children}</TableBody>;
}
function MarkdownTableHead({children}): JSX.Element {
  return <TableHead>{children}</TableHead>;
}

const MarkdownImage = ({...props}): JSX.Element => {
  return (
    <Image
      alt={props.title}
      height={props?.height !== 'undefined' ? props?.height : 500}
      width={props?.width !== 'undefined' ? props?.width : 500}
      src={props.src}
    />
  );
};

const MarkdownDivider = (): JSX.Element => {
  return <Divider />;
};

const MarkdownFigure = ({children}): JSX.Element => {
  return <Box>{children}</Box>;
};

const components: Options['components'] = {
  h1: MarkdownHeading,
  h2: MarkdownHeading,
  h3: MarkdownHeading,
  h4: MarkdownHeading,
  h5: MarkdownHeading,
  p: props => {
    const {children} = props;
    if (props.className === 'sectiontitle') {
      return <Typography className={styles.sectiontitle}>{children}</Typography>;
    } else {
      return (
        <Typography variant="body1" paragraph>
          {children}
        </Typography>
      );
    }
  },
  a: ({href, children, type, target, ..._props}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {locale} = useRouter();
    const replacedHref = href.replace(/https:\/\/www.starcar.de/gi, '');
    if (type === 'button' && href) {
      //       customURL
      // buttonText

      return (
        <NextLink href={replacedHref} locale={locale} target={target}>
          <span style={{display: 'block', textDecoration: 'none', margin: '5px 0'}}>
            <Button>{children[0]}</Button>
          </span>
        </NextLink>
      );
    }
    return (
      <NextLink style={{color: 'inherit'}} href={replacedHref} locale={locale} target={target}>
        {children[0]}
      </NextLink>
    );
  },
  i: props => {
    return <i {...props} />;
  },

  div: props => {
    // if (props.className === 'car-icon-door2') {
    //   return <Image height="10" width="8" src={doorImg} alt="door-icon" />;
    // }
    // if (props.className === 'divider') {
    //   return <StarcarTrenner />;
    // }
    // if (props.className === 'spacer50') {
    //   return <StarcarTrenner class="spacer50" />;
    // } else {
    return (
      <div
        {...props}
        className={Object.keys(styles)
          .filter(className => props?.className?.split(' ').includes(className))
          .map(className => styles[className])
          .join(' ')}
      />
    );
    // }
  },
  li: MarkdownListItem,
  ol: MarkdownListItemOl,
  img: MarkdownImage,
  table: MarkdownTable,
  thead: MarkdownTableHead,
  tbody: MarkdownTableBody,
  tr: MarkdownTableRow,
  td: MarkdownTableCell,
  th: MarkdownTableCell,
  hr: MarkdownDivider,
  figure: MarkdownFigure,
};

const hasDeepStrongElement = obj => {
  // Initialize a variable to keep track of whether a "strong" element has been found
  let hasStrong = false;

  // Check if the current object is a "strong" element
  if (obj.tagName === 'strong') {
    return true;
  }

  // If the current object has children
  if (obj.children) {
    // Iterate over each child element that is not an "ol" or "li" element
    // We skip iterating over "ol" and "li" elements because we don't want to look for "strong" elements inside them
    obj.children.forEach(child => {
      // Recursively call the function with the child element as the argument
      // If the recursive call returns true, set the hasStrong variable to true
      hasStrong = hasStrong || hasDeepStrongElement(child);
    });
  }

  // Return the final value of hasStrong, which will be true if a "strong" element was found in the current object or its children, and false otherwise
  return hasStrong;
};
const MarkdownContent = ({
  content,
  sx,
  className = '',
  linkTarget,
  transformLinkUri,
  transformImageUri,
}: IMardownContentProps): JSX.Element => {
  // console.log(content)
  return (
    <Box sx={sx} className={styles.wrapperStyles}>
      <ReactMarkdown
        className={className}
        components={components}
        rehypePlugins={[
          rehypeRaw,
          [
            rehypeRewrite,
            {
              rewrite: (node, index, parent) => {
                if (node.tagName === 'li' && hasDeepStrongElement(node)) {
                  node.isBold = true;
                }
                if (node.tagName === 'li' && parent?.properties?.style?.includes('square')) {
                  // console.log('rewrite', {node});
                  node.properties.isCheckmark = true;
                }
                if (
                  ['h1', 'h2', 'h3', 'h4', 'h5'].includes(node.tagName) &&
                  !!node?.children[0]?.properties?.className?.filter(className =>
                    ['h1', 'h2', 'h3', 'h4', 'h5'].includes(className),
                  )
                ) {
                  node.properties.className = node?.children[0]?.properties?.className;
                  node.children = node?.children[0]?.children;
                  // node.properties = {}
                }
              },
            },
          ],
        ]}
        // eslint-disable-next-line react/no-children-prop
        children={content}
        linkTarget={linkTarget}
        transformLinkUri={transformLinkUri}
        transformImageUri={transformImageUri}
      />
    </Box>
  );
};

export default withErrorBoundary(MarkdownContent, ' MarkdownContent');
