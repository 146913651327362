// import EmergencyModalHOC from 'HoC/EmergencyModalHOC';
import {CDWProps, CDWPropsContext} from 'context/cdwPropsContext';
import {DataPrivacyContext, DataPrivacyProps} from 'context/dataPrivacyContext';
import {InfoTextProps, InfoTextPropsContext} from 'context/infoTextPropsContext';
import {NavProps, NavPropsContext} from 'context/navPropsContext';

import {SharedSeoComponent} from 'lib/api/strapi';

import ConsentManager from 'components/section-components/ConsentManager';
import Seo from 'components/strapi-components/Seo';
import { NewFeaturesModalProps, NewFeaturesModalPropsContext } from 'context/NewFeaturesModalContext';

export type TDefaultProps = {
  navProps: NavProps;
  cdwProps: CDWProps;
  infoTextProps: InfoTextProps;
  dataPrivacyProps: DataPrivacyProps;
  seo?: SharedSeoComponent;
  newFeaturesModalProps: NewFeaturesModalProps;
};
export const DefaultPagePropsProvider = ({
  defaultProps,
  children,
}: {
  defaultProps: TDefaultProps;
  children: string | JSX.Element | JSX.Element[] | '() => JSX.Element';
}) => {
  const {navProps, cdwProps, infoTextProps, dataPrivacyProps, seo,newFeaturesModalProps} = defaultProps;
  return (
    <>
      {seo ? <Seo seoData={seo} /> : null}
      <NavPropsContext.Provider value={navProps}>
        <CDWPropsContext.Provider value={cdwProps}>
          <InfoTextPropsContext.Provider value={infoTextProps}>
            <DataPrivacyContext.Provider value={dataPrivacyProps}>
              <NewFeaturesModalPropsContext.Provider value={newFeaturesModalProps}>
              {/* <EmergencyModalHOC> */}
              <ConsentManager>{children}</ConsentManager>
              {/* </EmergencyModalHOC> */}
              </NewFeaturesModalPropsContext.Provider>
            </DataPrivacyContext.Provider>
          </InfoTextPropsContext.Provider>
        </CDWPropsContext.Provider>
      </NavPropsContext.Provider>
    </>
  );
};
